import React from "react";
import MenuTabs from "@components/Admin/MenuTabs";

const AdministratorMenu: React.FC = () => (
  <MenuTabs
    items={[
      {
        id: "AdministratorAdministratorNavigationTitle",
        path: "/admin/administrator",
        page: "Administrator",
        subpage: "SuperAdministrator",
      },
      {
        id: "AdministratorTechAdministratorNavigationTitle",
        path: "/admin/administrator/tech",
        page: "Administrator",
        subpage: "TechAdministrator",
      },
    ]}
  />
);

export default AdministratorMenu;
